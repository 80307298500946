import React, { useEffect, useState , useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import logo from './logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faChartBar,
  faVideo,
  faMicrophone,
  faMicrophoneSlash,
  faVideoCamera,
  faVideoSlash,
  faPhoneSlash
} from "@fortawesome/free-solid-svg-icons";
import './LeftNav.css';

const Modal = ({ show, onClose, setHideBackground, onNameSubmit }) => {
  const [inputValue, setInputValue] = useState("");
  const [localStream, setLocalStream] = useState(null);
  const videoRef = useRef(null);
  

  // Request video stream when modal is shown
  useEffect(() => {
    if (show) {
      const getUserMedia = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: false
          });
          setLocalStream(stream);
        } catch (error) {
          console.error("Error accessing media devices.", error);
        }
      };
      getUserMedia();
    } else {
      // Stop video stream when modal is closed
      if (localStream) {
        localStream.getTracks().forEach(track => track.stop());
        setLocalStream(null);
      }
    }
  }, [show]);

  // Set the video stream only once when the stream is available
  useEffect(() => {
    if (videoRef.current && localStream) {
      videoRef.current.srcObject = localStream;
    }
  }, [localStream]);

  const handleContinue = () => {
    if (inputValue.trim()) {
      onNameSubmit(inputValue);
      setHideBackground(true);
      onClose();
    }
  };

  return show ? (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="vedio-info-doxy d-flex">
              <div className="vedio-content-sec d-flex flex-column justify-content-center">
                <h2 className="fs-5 fw-sami-bold">Welcome!</h2>
                <p>Please check in below to let the host know you are here</p>
                <input
                  className="my-3 p-2"
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Enter your name..."
                />
                <button className="continue-btn" onClick={handleContinue}>
                  Continue
                </button>
              </div>
              <div className="video-container">
                <video
                  ref={videoRef} // Use the ref for the video element
                  style={{ width: "400px" }}
                  autoPlay
                  playsInline
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};




const CallPage = () => {
  const { id } = useParams(); // Extract call ID from URL
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [pc, setPC] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [showModal, setShowModal] = useState(true); // Show the modal initially
  const [hideBackground, setHideBackground] = useState(false);
  const [isMicOn, setIsMicOn] = useState(true);
  const [isVideoOn, setIsVideoOn] = useState(true);
  const [isRemoteVideoVisible, setIsRemoteVideoVisible] = useState(false); // Remote video visibility
  const [callStatus, setCallStatus] = useState("pending"); // Call status
  const navigate = useNavigate();
  const [showVideoSection, setShowVideoSection] = useState(false);
 
 

  useEffect(() => {
    const initializeFirebase = () => {
      const firebaseConfig = {
        apiKey: "AIzaSyCxsZjE8xqTltmPyzMhTC3Ho1BvupBUu6Q",
        authDomain: "vediocallapp-bc493.firebaseapp.com",
        projectId: "vediocallapp-bc493",
        storageBucket: "vediocallapp-bc493.appspot.com",
        messagingSenderId: "481852095636",
        appId: "1:481852095636:web:aa2e66ca3daeeebe6a0881",
      };

      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      }
      setInitialized(true);
    };

    initializeFirebase();
  }, []);

  useEffect(() => {
    const initializeCall = async () => {
      const firestore = firebase.firestore();
      const callDoc = firestore.collection("calls").doc(id);
      const answerCandidates = callDoc.collection("answerCandidates");
      const offerCandidates = callDoc.collection("offerCandidates");
  
      // Get user media (video/audio)
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
        },
      });
      setLocalStream(stream);
  
      // Create a new RTCPeerConnection
      const newPC = new RTCPeerConnection({
        iceServers: [
          {
            urls: [
              "stun:stun1.l.google.com:19302",
              "stun:stun2.l.google.com:19302",
            ],
          },
        ],
        iceCandidatePoolSize: 10,
      });
  
      // Handle incoming tracks
      newPC.ontrack = (event) => {
        if (event.streams && event.streams[0]) {
          setRemoteStream(event.streams[0]);
        }
      };
  
      // Add local stream tracks to the peer connection
      stream.getTracks().forEach((track) => {
        newPC.addTrack(track, stream);
      });
  
      // Handle ICE candidates
      newPC.onicecandidate = (event) => {
        if (event.candidate) {
          answerCandidates.add(event.candidate.toJSON());
        }
      };
  
      // Get the call data and handle incoming offer
      const callData = (await callDoc.get()).data();
      const offerDescription = callData ? callData.offer : null;
  
      if (offerDescription) {
        await newPC.setRemoteDescription(new RTCSessionDescription(offerDescription));
  
        const answerDescription = await newPC.createAnswer();
        await newPC.setLocalDescription(answerDescription);
  
        const answer = {
          type: answerDescription.type,
          sdp: answerDescription.sdp,
        };
  
        await callDoc.update({ answer });
  
        // Listen for ICE candidates
        offerCandidates.onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              const candidate = new RTCIceCandidate(change.doc.data());
              newPC.addIceCandidate(candidate);
            }
          });
        });
  
        setPC(newPC);
      }
  
      // Listen for call status updates
      const unsubscribe = callDoc.onSnapshot((docSnapshot) => {
        const callData = docSnapshot.data();
        if (callData) {
          if (callData.status === "accepted") {
            setIsRemoteVideoVisible(true); // Show remote video when call is accepted
          } else if (callData.status === "ended") {
            // Handle the call end logic for both users
            handleCallEnd();
          }
          setCallStatus(callData.status || "pending");
        }
      });
  
      // Cleanup function to unsubscribe from Firestore on component unmount
      return () => {
        unsubscribe();
        if (newPC) {
          newPC.close(); // Close the peer connection if it exists
        }
      };
    };
  
    if (initialized) {
      initializeCall();
    }
  }, [id, initialized]);
  const handleCallEnd = () => {
    // Clean up local streams and reset state
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
      setLocalStream(null);
    }
  
    if (remoteStream) {
      setRemoteStream(null);
    }
  
    if (pc) {
      pc.close(); // Close the peer connection
      setPC(null);
    }
  
    setShowVideoSection(false); // Hide the video section
    setCallEnded(true); // Set call ended state to true
    setCallStatus(false); // Update the call active state
  };
  const handleHangUp = async () => {
    try {
      const firestore = firebase.firestore();
      const callDoc = firestore.collection("calls").doc(id);
  
      // Update the call status to "ended"
      await callDoc.update({
        status: "ended",
        endedAt: firebase.firestore.FieldValue.serverTimestamp(), // Optionally store the end time
        isExpired: true, // Mark the call link as expired
      });
  
      console.log("Call status updated to ended.");
    } catch (error) {
      console.error("Error updating call status:", error);
    }
  
    // Stop local stream and close peer connection
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
    }
    if (pc) {
      pc.close();
    }
  
    // Update state to show call ended message
    setCallEnded(true);
  };
  
  
  

  const toggleMicrophone = () => {
    if (localStream) {
      localStream.getAudioTracks()[0].enabled = !isMicOn;
      setIsMicOn(!isMicOn);
    }
  };
  const toggleCamera = () => {
    if (localStream) {
      // Get the video tracks from the local stream
      const videoTracks = localStream.getVideoTracks();
  
      // Toggle the enabled state of the video track
      videoTracks.forEach((track) => {
        track.enabled = !track.enabled; // Enable or disable the track
      });
    }
    setIsVideoOn((prev) => !prev); // Update the camera state
  };

  const handleNameSubmit = async (name) => {
    try {
      const firestore = firebase.firestore();
      const callDoc = firestore.collection("calls").doc(id);
      
      // Update call status to pending and save the participant's name
      await callDoc.update({
        participantName: name,
        status: "pending",
        joinedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      
      console.log("Participant name and status updated to pending.");
    } catch (error) {
      console.error("Error updating Firestore:", error);
    }
  };

  return (
    <div className="d-flex flex-column h-100">
      <Modal 
        show={showModal} 
        onClose={() => setShowModal(false)} 
        setHideBackground={setHideBackground}
        onNameSubmit={handleNameSubmit} // Pass the function to the modal
      />
      <div className="d-flex flex-fill">
        <div className="left-nav bg-dark text-light p-3 d-flex flex-column">
          <img className="mb-3" style={{ width: "110px", height: 'auto' }} src={logo} alt="Logo" />
          <ul className="nav-left-nav flex-column">
            <li className="nav-item mb-2">GENERAL</li>
            <li className="border-bottom mb-1"></li>
            <li className="nav-item text-left-a mb-2">No items</li>
          </ul>
        </div>

        {!isRemoteVideoVisible && !showModal && localStream && (
  <>
    <div className="local-video" style={{ position: "absolute", right: "20px", zIndex: 1, top: "20px" }}>
      <video
        style={{ width: "200px", height: "200px", objectFit: "cover" }}
        ref={(ref) => {
          if (ref) {
            // Only set srcObject once to avoid blinking
            if (!ref.srcObject) {
              ref.srcObject = localStream;
            }
          }
        }}
        autoPlay
        muted
        playsInline
      ></video>

      {/* Microphone Toggle Button */}
      <button className="btn btn-dark" onClick={toggleMicrophone} style={{
        position: "absolute",
        top: "10px",
        right: "50px", // Adjust position for camera button
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        border: "none",
        padding: "0"
      }}>
        <FontAwesomeIcon icon={isMicOn ? faMicrophone : faMicrophoneSlash} style={{ color: "white" }} />
      </button>

      {/* Camera Toggle Button */}
      <button className="btn btn-dark" onClick={toggleCamera} style={{
        position: "absolute",
        top: "10px",
        right: "10px", // Adjust position for microphone button
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        border: "none",
        padding: "0"
      }}>
        <FontAwesomeIcon icon={isVideoOn ? faVideoCamera : faVideoSlash} style={{ color: "white" }} />
      </button>
    </div>
  </>
)}



{isRemoteVideoVisible && (
  <div 
    className={`content-area ${hideBackground ? "hide-background" : ""}`} 
    style={{ height: "100vh", width: "100vw", position: "relative", overflow: "hidden" }}
  >
    {/* Remote Video */}
    <div className="remote-video" style={{ height: "100%", width: "100%", filter: callEnded ? "blur(8px)" : "none" }}>
      <video
        ref={(ref) => {
          if (ref && !ref.srcObject) {
            ref.srcObject = remoteStream; // Assign stream only once
          }
        }}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "fill",
          position: "absolute",
          top: 0,
          left: 0
        }}
        autoPlay
        playsInline
      ></video>
    </div>

    {/* Local Video (small inset) */}
    <div className="local-video" style={{ position: "absolute", right: "20px", zIndex: 1, top: "20px", filter: callEnded ? "blur(8px)" : "none" }}>
      <video
        ref={(ref) => {
          if (ref && !ref.srcObject) {
            ref.srcObject = localStream; // Assign stream only once
          }
        }}
        style={{
          width: "200px",
          height: "200px",
          objectFit: "cover"
        }}
        autoPlay
        muted
        playsInline
      ></video>
    </div>

    {/* Centered Controls */}
    <div className="controls" style={{
      position: "absolute",
      bottom: "5%",
      left: "50%",
      transform: "translate(-50%, 0)", // Center the buttons
      display: "flex",
      flexDirection: "row",
      gap: "20px", // Space between buttons
      zIndex: 2,
      filter: callEnded ? "blur(8px)" : "none" // Blur controls if call ended
    }}>
      <button 
        className="btn btn-danger" 
        onClick={handleHangUp} 
        style={{
          width: "60px",
          height: "60px",
          borderRadius: "50%", // Make the button round
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <FontAwesomeIcon icon={faPhoneSlash} />
      </button>

      <button 
        className="btn btn-dark" 
        onClick={toggleMicrophone} 
        style={{
          width: "60px",
          height: "60px",
          borderRadius: "50%", // Make the button round
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <FontAwesomeIcon icon={isMicOn ? faMicrophone : faMicrophoneSlash} />
      </button>

      <button 
        className="btn btn-dark" 
        onClick={toggleCamera} 
        style={{
          width: "60px",
          height: "60px",
          borderRadius: "50%", // Make the button round
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <FontAwesomeIcon icon={isVideoOn ? faVideoCamera : faVideoSlash} />
      </button>
    </div>

    {/* Call Ended Message */}
    {callEnded &&  (
      <div 
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          padding: "20px",
          borderRadius: "10px",
          textAlign: "center",
          zIndex: 3
        }}
      >
        <h2 style={{ margin: 0 }}>Call Ended</h2>
        <p>Thank you for using our service.</p>
      </div>
    )}
  </div>
)}




      </div>
    </div>
  );
};

export default CallPage;
