import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import "firebase/auth"; // Import Firebase Authentication
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { loginSuccess, loginFailure, loginStart } from '../../components/redux/user/UserSlice';

function SignUp() {
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    // Initialize Firebase inside useEffect
    const initializeFirebase = () => {
      const firebaseConfig = {
        apiKey: "AIzaSyCxsZjE8xqTltmPyzMhTC3Ho1BvupBUu6Q",
        authDomain: "vediocallapp-bc493.firebaseapp.com",
        projectId: "vediocallapp-bc493",
        storageBucket: "vediocallapp-bc493.appspot.com",
        messagingSenderId: "481852095636",
        appId: "1:481852095636:web:aa2e66ca3daeeebe6a0881",
      };

      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      } else {
        firebase.app(); // if already initialized, use that one
      }
    };

    initializeFirebase();
  }, []); // Empty dependency array to run only on component mount

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formData;

    try {
      dispatch(loginStart());
      // Firebase sign-up process using email and password
      const auth = firebase.auth(); // Get Firebase auth instance
      const userCredential = await auth.createUserWithEmailAndPassword(email, password);
      const user = userCredential.user;

      // Optionally, store the token or user info in localStorage
      const token = await user.getIdToken();
      localStorage.setItem('token', token);

      dispatch(loginSuccess({ token, user }));
      navigate('/homepage'); // Navigate to homepage after successful sign-up
    } catch (error) {
      dispatch(loginFailure(error.message));
    }
  };

  return (
    <div className="signup-body">
      <div className="main-signup d-flex align-items-center justify-content-center h-100">
        <div className="signup-page-bg shadow-lg">
          <div className="brand-logo text-center">
            <h2>Sign Up</h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="signup-inputs d-flex align-items-center justify-content-center flex-column">
              <div className="input-group mb-3">
                <span className="input-group-text" style={{ height: "47px", backgroundColor: "#06B5C9" }}>Email</span>
                <div className="form-floating">
                  <input type="text" className="form-control" id="email" placeholder="Email" onChange={handleChange} />
                  <label htmlFor="email">Email</label>
                </div>
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text" style={{ height: "47px", backgroundColor: "#06B5C9" }}>Password</span>
                <div className="form-floating">
                  <input type="password" className="form-control" id="password" placeholder="Password" onChange={handleChange} />
                  <label htmlFor="password">Password</label>
                </div>
              </div>
              <div className="btn-signup shadow-sm">
                <button type="submit" className="btn btn-signup" style={{ fontWeight: "bold" }}>
                  Sign Up
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
