import React from 'react'
import LeftNav from '../components/LeftNav'
const HomePage = () => {
  return (
    <div>
        <LeftNav/>

    </div>
  )
}

export default HomePage