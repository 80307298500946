
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import './LeftNav.css'


export default function MeetingHistory({userId}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [meetingHistory, setMeetingHistory] = useState([]);
  useEffect(() => {
    const fetchMeetingHistory = async () => {
      const userDoc = firebase.firestore().collection('users').doc(userId);
      const meetingsSnapshot = await userDoc.collection('meetingHistory').get();

      console.log("doc",meetingsSnapshot)
      const meetingsData = meetingsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setMeetingHistory(meetingsData);
    };

    fetchMeetingHistory();
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
console.log("meeting",meetingHistory)
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    <h2 className='fs-4'>Analytics</h2>
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead className='vedio-table'>
          <TableRow>
            <TableCell align='left' sx={{ minWidth: 50 }}>No</TableCell>
            <TableCell align='left' sx={{ minWidth: 150 }}>Name</TableCell>
            <TableCell align='left' sx={{ minWidth: 150 }}>Date</TableCell>
            <TableCell align='left' sx={{ minWidth: 150 }}>Start Time</TableCell>
            <TableCell align='left' sx={{ minWidth: 150 }}>End Time</TableCell>
            <TableCell align='left' sx={{ minWidth: 150 }}>Duration</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {meetingHistory
            .sort((a, b) => {
              const dateA = a.startTime ? a.startTime.seconds : 0;
              const dateB = b.startTime ? b.startTime.seconds : 0;
              return dateB - dateA; // Sort in descending order (latest first)
            })
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const displayDate = row.date 
                ? new Date(row.date.seconds * 1000).toLocaleDateString() 
                : row.startTime 
                ? new Date(row.startTime.seconds * 1000).toLocaleDateString() 
                : 'N/A';
              
              const startDate = row.startTime ? new Date(row.startTime.seconds * 1000) : null;
              const endDate = row.endTime ? new Date(row.endTime.seconds * 1000) : null;
  
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  <TableCell align='left' sx={{ minWidth: 50 }}>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell align='left' sx={{ minWidth: 150 }}>{row.participantName || 'N/A'}</TableCell>
                  <TableCell align='left' sx={{ minWidth: 150 }}>{displayDate}</TableCell>
                  <TableCell align='left' sx={{ minWidth: 150 }}>{startDate ? startDate.toLocaleTimeString() : 'N/A'}</TableCell>
                  <TableCell align='left' sx={{ minWidth: 150 }}>{endDate ? endDate.toLocaleTimeString() : 'N/A'}</TableCell>
                  <TableCell align='left' sx={{ minWidth: 150 }}>{row.duration ? `${row.duration} mins` : 'N/A'}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={meetingHistory.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </Paper>
  
  );
}
