import logo from './logo.svg';
// import './App.css';
import HomePage from './pages/HomePage';
import Login from './pages/login/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LeftNav from './components/LeftNav';
import CallPage from './components/CallPage';
import SignUp from './pages/login/SignUp';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  return (
    <BrowserRouter>
      
    <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/signup" element={<SignUp />} />
    <Route path="/call/:id" element={<CallPage />} /> 
    {/* <Route path="/homepage" element={<CallPage />} />  */}
    <Route path="/homepage" element={<HomePage />} />

  
      
    </Routes>
  </BrowserRouter>
  );
}

export default App;
{/* <li >
{pendingUsers.length > 0 ? (
  <ul className="pending-users-list">
      {pendingUsers.length > 0 ? pendingUsers.map(user => (
<li key={user.id} className="pending-user-item">
<span>{user.name}</span>
<span>{waitingTimes[user.id]}</span>
<button onClick={() => handleJoinCall(user.id)}>Start Call</button>
</li>
)) : (
<li>No pending users</li>
)}
</ul>

):<li className="nav-item text-left-a mb-2">No one has checked in yet</li>}
</li> */}