// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCxsZjE8xqTltmPyzMhTC3Ho1BvupBUu6Q",
  authDomain: "vediocallapp-bc493.firebaseapp.com",
  projectId: "vediocallapp-bc493",
  storageBucket: "vediocallapp-bc493.appspot.com",
  messagingSenderId: "481852095636",
  appId: "1:481852095636:web:aa2e66ca3daeeebe6a0881",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);