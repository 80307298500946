import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { loginStart, loginSuccess, loginFailure } from '../../components/redux/user/UserSlice';
import { auth } from '../../components/firebase/firebaseConfig'; // Import Firebase Auth
import { signInWithEmailAndPassword } from "firebase/auth"; // Firebase Auth Function
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './Login.css'

function Login() {
  const [loading, error] = useSelector((state) => [state.user.loading, state.user.error]);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  let navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formData;

    try {
      dispatch(loginStart());
      // Use Firebase to sign in
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // You can save user token or info in local storage if needed
      localStorage.setItem('token', user.accessToken);
      dispatch(loginSuccess({ token: user.accessToken, user }));

      navigate('/homepage');
    } catch (error) {
      dispatch(loginFailure(error.message));
    }
  };

  return (
    <div className="login-body">
      <div className="main-login d-flex align-items-center justify-content-center h-100">
        <div className="login-page-bg shadow-lg">
          <div className="brand-logo text-center">
            {/* <img src={Logo} alt="Soul Housing Logo" /> */}
            <h2>Meeting Portal</h2>
          </div>
          <form onSubmit={handleSubmit}>
          <div className="login-inputs d-flex align-items-center justify-content-center flex-column">
            <div className="input-group mb-3">
              <span className="input-group-text " style={{height:"47px",backgroundColor:"#06B5C9"}}><FontAwesomeIcon  icon={faUser} /></span>
              <div className="form-floating">
                <input  type="text" className="form-control " id="email" placeholder="Username" onChange={handleChange}/>
                <label htmlFor="email">Username</label>
              </div>
            </div>
            <div className="input-group flex-nowrap ">
              <div className="input-group mb-3">
                <span className="input-group-text" style={{height:"47px",backgroundColor:"#06B5C9"}}><FontAwesomeIcon icon={faLock} /></span>
                <div className="form-floating">
                  <input type="password" className="form-control" id="password" placeholder="Password" onChange={handleChange}/>
                  <label htmlFor="password">Password</label>
                </div>
              </div>
            </div>
            <div className="btn-signin shadow-sm">
              <button type="submit" className="btn btn-signin" style={{fontWeight:"bold"}} > {loading ? "loading.." : "Sign In"}</button>
            </div>
          </div>
          </form>
 
        </div>
      </div>
    </div>
  );

 
}

export default Login;
