import "firebase/compat/firestore";

import React, { useEffect, useState, useRef } from "react";
import "./LeftNav.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faChartBar,
  faVideo,
  faMicrophone,
  faMicrophoneSlash,
  faVideoCamera,
  faVideoSlash,
  faPhoneSlash,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../components/logo.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MeetingHistory from "./MeetingHistory";
import { left } from "@popperjs/core";
import { Refresh } from "@mui/icons-material";
const LeftNav = () => {
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [pc, setPC] = useState(null);
  const [callInputValue, setCallInputValue] = useState("");
  const [initialized, setInitialized] = useState(false);
  const [firestore, setFirestore] = useState(null);
  const [callLink, setCallLink] = useState("");
  const [showVideoSection, setShowVideoSection] = useState(false);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [isMicEnabled, setIsMicEnabled] = useState(true); // mic on/off state
  const [isVideoEnabled, setIsVideoEnabled] = useState(true); // video on/off state
  const [isCallActive, setIsCallActive] = useState(false);
  const [isRemoteVideoVisible, setIsRemoteVideoVisible] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [historyUser, setHistoryUser] = useState(null);

  const webcamVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);

  const navigate = useNavigate();
  const [pendingUsers, setPendingUsers] = useState([]);
  const token = useSelector((state) => state.user.currentUser.token);
  const user = useSelector((state) => state.user.currentUser.user);

  console.log("user", user);
  useEffect(() => {
    if (firestore) {
      const unsubscribe = firestore
        .collection("calls")
        .onSnapshot((snapshot) => {
          const newPendingUsers = snapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .filter((user) => user.status === "pending"); // Filter only 'pending' or 'created' status

          setPendingUsers(newPendingUsers);
        });

      return () => unsubscribe();
    }
  }, [firestore]);

  const handleJoinCall = async (userId, participantName) => {
    const userDoc = firestore.collection("calls").doc(userId);

    try {
      // Update the call status to "accepted"
      await userDoc.update({
        status: "accepted",
        startTime: firebase.firestore.FieldValue.serverTimestamp(),
      });
      console.log("Call accepted for user", userId);

      // Store meeting history
      const meetingHistoryDoc = firestore
        .collection("users")
        .doc(user.uid)
        .collection("meetingHistory")
        .doc(userId);
      await meetingHistoryDoc.set({
        callId: userId,
        participantName: participantName,
        startTime: firebase.firestore.FieldValue.serverTimestamp(),
        status: "ongoing",
        date: date,
      });

      setIsCallActive(true);
      setIsRemoteVideoVisible(true);
      setHistoryUser(participantName);
      console.log("historyUser set to:", participantName);
    } catch (error) {
      console.error("Error joining the call:", error);
    }
  };

  const [waitingTimes, setWaitingTimes] = useState({});

  // Function to calculate waiting time
  const calculateWaitingTime = (timestamp) => {
    if (timestamp instanceof firebase.firestore.Timestamp) {
      const enteredTime = timestamp.toDate();
      const now = new Date();
      const differenceInSeconds = Math.floor((now - enteredTime) / 1000);
      const minutes = Math.floor(differenceInSeconds / 60);
      const seconds = differenceInSeconds % 60;
      return `${minutes > 0 ? `${minutes}m ` : ""}${seconds}s`;
    }
    return "N/A";
  };

  // Function to update waiting times
  const updateWaitingTimes = () => {
    setWaitingTimes((prevWaitingTimes) => {
      const updatedWaitingTimes = {};
      pendingUsers.forEach((user) => {
        updatedWaitingTimes[user.id] = calculateWaitingTime(user.timestamp);
      });
      return updatedWaitingTimes;
    });
  };

  // Use interval to update waiting times every second
  useEffect(() => {
    updateWaitingTimes(); // Initial update

    const intervalId = setInterval(updateWaitingTimes, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [pendingUsers]);
  useEffect(() => {
    const initializeFirebase = () => {
      const firebaseConfig = {
        apiKey: "AIzaSyCxsZjE8xqTltmPyzMhTC3Ho1BvupBUu6Q",
        authDomain: "vediocallapp-bc493.firebaseapp.com",
        projectId: "vediocallapp-bc493",
        storageBucket: "vediocallapp-bc493.appspot.com",
        messagingSenderId: "481852095636",
        appId: "1:481852095636:web:aa2e66ca3daeeebe6a0881",
      };

      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      }
      setFirestore(firebase.firestore());
      setInitialized(true);
    };

    initializeFirebase();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleWebcamButtonClick = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
      },
    });
    setLocalStream(stream);
    setShowVideoSection(true);

    const newPC = new RTCPeerConnection({
      iceServers: [
        {
          urls: [
            "stun:stun1.l.google.com:19302",
            "stun:stun2.l.google.com:19302",
          ],
        },
      ],
      iceCandidatePoolSize: 10,
    });

    newPC.ontrack = (event) => {
      if (event.streams && event.streams[0]) {
        setRemoteStream(event.streams[0]);
        console.log("Remote stream received");
        if (remoteVideoRef.current) {
          remoteVideoRef.current.srcObject = event.streams[0];
        }
      }
    };

    stream.getTracks().forEach((track) => {
      newPC.addTrack(track, stream);
    });

    setPC(newPC);
  };

  const handleToggleMic = () => {
    if (localStream) {
      const audioTrack = localStream.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = !audioTrack.enabled;
        setIsMicEnabled(audioTrack.enabled);
      }
    }
  };

  const handleToggleVideo = () => {
    if (localStream) {
      const videoTrack = localStream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = !videoTrack.enabled;
        setIsVideoEnabled(videoTrack.enabled);
      }
    }
  };
  var date = new Date();
  const handleCallButtonClick = async () => {
    if (!localStream) {
        // Show an alert or modal requesting the user to enable the camera
        alert("Please enable your camera before creating a link.");
        return; // Exit early
    }

    if (!pc || pc.signalingState === "closed") {
        const newPC = new RTCPeerConnection({
            iceServers: [
                {
                    urls: [
                        "stun:stun1.l.google.com:19302",
                        "stun:stun2.l.google.com:19302",
                    ],
                },
            ],
            iceCandidatePoolSize: 10,
        });

        setPC(newPC);

        newPC.ontrack = (event) => {
            if (event.streams && event.streams[0]) {
                setRemoteStream(event.streams[0]);
                if (remoteVideoRef.current) {
                    remoteVideoRef.current.srcObject = event.streams[0];
                }
            }
        };

        if (localStream) {
            localStream.getTracks().forEach((track) => {
                newPC.addTrack(track, localStream);
            });
        }
    }

    const callDoc = firestore.collection("calls").doc();
    const offerCandidates = callDoc.collection("offerCandidates");
    const answerCandidates = callDoc.collection("answerCandidates");

    setCallInputValue(callDoc.id);

    pc.onicecandidate = (event) => {
        event.candidate && offerCandidates.add(event.candidate.toJSON());
    };

    const offerDescription = await pc.createOffer();
    await pc.setLocalDescription(offerDescription);
    const offer = {
        sdp: offerDescription.sdp,
        type: offerDescription.type,
    };

    const currentDate = new Date();
    const dateKey = currentDate.toISOString().split("T")[0];

    await callDoc.set({
        offer,
        status: "created",
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        startTime: firebase.firestore.FieldValue.serverTimestamp(),
    });

    const userDoc = firestore.collection("users").doc(user.uid);
    const meetingHistoryDoc = userDoc.collection("meetingHistory").doc(callDoc.id);
    
    callDoc.onSnapshot((snapshot) => {
        const data = snapshot.data();
        if (data?.answer && pc.signalingState !== "closed") {
            const answerDescription = new RTCSessionDescription(data.answer);
            pc.setRemoteDescription(answerDescription).catch((error) => {
                console.error("Error setting remote description:", error);
            });
        }
    });

    answerCandidates.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
            if (change.type === "added" && pc.signalingState !== "closed") {
                const candidate = new RTCIceCandidate(change.doc.data());
                pc.addIceCandidate(candidate).catch((error) => {
                    console.error("Error adding ICE candidate:", error);
                });
            }
        });
    });

    const callLink = `https://video.myaidoctor.online/call/${callDoc.id}`;
    setCallLink(callLink);
};

  
  useEffect(() => {
    if (localStream && webcamVideoRef.current) {
      webcamVideoRef.current.srcObject = localStream;
    }
  }, [localStream]);

  const handleHangupButtonClick = async () => {
    if (pc) {
      const callDoc = firestore.collection("calls").doc(callInputValue);
      const userDoc = firestore.collection("users").doc(user.uid);
      const meetingHistoryDoc = userDoc.collection("meetingHistory").doc(callInputValue);
  
      try {
        const endTime = firebase.firestore.FieldValue.serverTimestamp(); // Capture end time
        const callSnapshot = await callDoc.get();
        const callData = callSnapshot.data();
  
        const startTime = callData.startTime?.toDate();
        const now = new Date();
        const durationInSeconds = Math.floor((now - startTime) / 1000);
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = durationInSeconds % 60;
        const duration = `${minutes}m ${seconds}s`;
  
        // Update the status, endTime, and duration in Firestore
        await callDoc.update({
          status: "ended",
          endTime: endTime,
          duration: duration,
          isExpired: true,
        });
  
        // Notify the other user(s) about the call ending
        // (Optional) You can implement a separate notification system here if needed
  
        // Also update the user's meeting history with end time and duration
        await meetingHistoryDoc.update({
          endTime: endTime,
          duration: duration,
          status: "ended",
        });
  
        // Close peer connection and clean up local streams
        pc.close();
        setPC(null);
        setRemoteStream(null);
        setLocalStream((stream) => {
          stream.getTracks().forEach((track) => track.stop());
          return null;
        });
        setShowVideoSection(false);
        setShowThankYouMessage(true);
        setIsCallActive(false);
        handleCallEnd();
      } catch (error) {
        console.error("Error ending the call:", error);
      }
    }
  };

  const handleCallEnd = () => {
    // Clean up local streams, reset state, etc.
   navigate(Refresh);
  };

  const handleCopyLinkButtonClick = () => {
    navigator.clipboard
      .writeText(callLink)
      // .then(() => {
      //   alert("Link copied to clipboard!");
      // })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
  };
  const [updateKey, setUpdateKey] = useState(0);

  const forceUpdate = () => {
    setUpdateKey((prevKey) => prevKey + 1);
  };

  // Call `forceUpdate` in places where you update remote stream

  useEffect(() => {
    if (remoteStream && remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = remoteStream;
      remoteVideoRef.current.play().catch((error) => {
        console.error("Error playing remote video:", error);
      });
    }
  }, [remoteStream, updateKey]);
  useEffect(() => {
    if (firestore && callInputValue) {
      const callDoc = firestore.collection("calls").doc(callInputValue);
  
      const unsubscribe = callDoc.onSnapshot((snapshot) => {
        const callData = snapshot.data();
        if (callData && callData.status === "accepted") {
          setIsRemoteVideoVisible(true); // Show remote video when call is accepted
          // Check if the remote stream is available and play it
          if (remoteStream && remoteVideoRef.current) {
            remoteVideoRef.current.srcObject = remoteStream;
            remoteVideoRef.current.play().catch((error) => {
              console.error("Error playing remote video:", error);
            });
          }
        }
      });
  
      return () => unsubscribe();
    }
  }, [firestore, callInputValue, remoteStream]);  // Add remoteStream to the dependency array
  
  
  console.log("pending user", pendingUsers);

  const handleRemoveUser = async (userId, name) => {
    try {
      const userDoc = firestore.collection("calls").doc(userId);
      await userDoc.delete(); // Or update status to 'removed'
      alert(`${name} has been removed from the queue.`); 
    } catch (error) {
      console.error("Error removing user:", error);
    }
  };








  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex flex-fill">
        <div className="left-nav bg-dark text-light p-3 d-flex flex-column">
          <img
            className="mb-3"
            style={{ width: "110px", height: "auto" }}
            src={logo}
            alt="Logo"
          />
          <ul className="nav-left-nav flex-column">
            <li className="nav-item mb-2">PATIENT QUEUE</li>
            <li className="border-bottom mb-1"></li>
            {pendingUsers.length > 0 ? (
            <ul className="pending-users-list">
              {pendingUsers.map((user) => {
                const waitingTime =
                  waitingTimes[user.id] ||
                  calculateWaitingTime(user.timestamp);

                return user.status !== "ended" ? (
                  <li
                    className="main-call-btn d-flex justify-content-center"
                    key={user.id}
                  >
                    <div className="waiting-btn d-flex align-items-center flex-column">
                      <span className="user-roxy">
                        {user.participantName}
                      </span>
                      <span className="waiting-time">
                        waiting {waitingTime}
                      </span>
                    </div>
                    <div className="start-call-btn">
                      <button
                        style={{
                          fontSize: "12px",
                          width: "100px",
                          paddingTop: "2px",
                          paddingBottom: "2px",
                        }}
                        onClick={() =>
                          handleJoinCall(user.id, user.participantName)
                        }
                        className="btn btn-success btn-start-call"
                      >
                        Start Call
                      </button>
                    </div>
                    <div className="start-call-btn">
                      <button
                        style={{
                          fontSize: "12px",
                          width: "80px",
                          paddingTop: "2px",
                          paddingBottom: "2px",
                        }}
                        onClick={() => handleRemoveUser(user.id,user.participantName)}
                        className="btn btn-danger btn-remove-call"
                      >
                        Remove
                      </button>
                    </div>
                  </li>
                ) : null;
              })}
            </ul>
          ) : (
            <li className="nav-item text-left-a mb-2">
              No one has checked in yet
            </li>
          )}

            <li className="nav-item mb-2">GENERAL</li>
            <li className="border-bottom mb-2"></li>
            <li className="nav-item mb-2">
              <a href="#" className="nav-link text-light">
                <FontAwesomeIcon icon={faTachometerAlt} /> Dashboard
              </a>
            </li>
            <li className="nav-item mb-2">
              <a href="#" className="nav-link text-light">
                <FontAwesomeIcon icon={faChartBar} /> Analytics
              </a>
            </li>
            <li className="nav-item" onClick={() => setShowHistory(true)}>
              <a href="#" className="nav-link text-light">
                <FontAwesomeIcon icon={faVideo} /> Meeting History
              </a>
            </li>
          </ul>
          <div className="mt-auto text-center">
            <button className="w-100 logout-btn" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
        
              
        {showHistory ? (
          <MeetingHistory userId={user.uid} />
        ) : (
          <>
            <div className="right-sec flex-fill position-relative">

              <div className="row ">
                <div className="col-lg-8">
                  {!isRemoteVideoVisible && (
                    <div className="vedios-cards shadow-sm d-flex flex-column">
                      <h5 className="mb-3">
                        Welcome, {user.name && user.name}!
                      </h5>
                      <p className="mb-3">
                        To invite someone to your waiting room, share this link:
                      </p>
                      <div className="d-flex">
                        <div className="input-group">
                          <input
                            value={callLink}
                            onChange={(e) => setCallInputValue(e.target.value)}
                            id="callInput"
                            className="form-control"
                          />
                          <button
                            className="btn btn-info btn-copy"
                            onClick={handleCopyLinkButtonClick}
                          >
                            Copy Link
                          </button>
                          <button
                            onClick={handleCallButtonClick}
                            id="callButton"
                            className="btn btn-light create-call"
                          >
                            Create Link
                          </button>
                        </div>
                       
                      </div>
                    </div>
                  )}
   
                </div>

                <div className="w-v-container" style={{ position: "absolute", top: 0,  width: "99%", height: "100%", overflow: "hidden" }}>
  {isRemoteVideoVisible && (
    <video
      ref={remoteVideoRef}
      id="remoteVideo"
      autoPlay
      playsInline
      style={{
        objectFit: "fill",     // Ensure video covers the entire area while maintaining aspect ratio
        position: "absolute",   // Make it absolute to fit the parent container
        top: 0,                 // Align top
        left: 0,                // Align left
        width: "100%",          // Cover full width of the container
        height: "100%",  
               // Cover full height of the container
      }}
    />
  )}
</div>



<div className="col-lg-3">
                  <div
                    className="video-container"
                    style={{ width: "100%" }}
                  >
                    
                    {showVideoSection ? (
                      <>
                        <video style={{position:"relative"}}
                          ref={webcamVideoRef}
                          id="webcamVideo"
                          autoPlay
                          muted
                          playsInline
                          width="100%"
                          height="auto"
                        />
                       
                        <div className="icon-overlay">
                          <FontAwesomeIcon
                            icon={
                              isMicEnabled ? faMicrophone : faMicrophoneSlash
                            }
                            style={{
                              backgroundColor: isMicEnabled
                                ? "grey"
                                : "rgba(253, 25, 25, 0.925)",
                              padding: "8px",
                              borderRadius: "50%",
                            }}
                            className="icon"
                            onClick={handleToggleMic}
                          />
                          <FontAwesomeIcon
                            icon={isVideoEnabled ? faVideoCamera : faVideoSlash}
                            style={{
                              backgroundColor: isVideoEnabled
                                ? "grey"
                                : "rgba(253, 25, 25, 0.925)",
                              padding: "8px",
                              borderRadius: "50%",
                            }}
                            className="icon"
                            onClick={handleToggleVideo}
                          />
                          <FontAwesomeIcon
                            icon={faPhoneSlash}
                            style={{ color: "white" }}
                            className="icon camera-icon"
                            onClick={handleHangupButtonClick}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="vedios-roxy d-flex flex-column align-items-center mb-2">
                        <p className="text-light">
                          Your webcam <br />
                          isn't enabled yet.
                        </p>
                        <button
                          className="btn btn-primary"
                          onClick={handleWebcamButtonClick}
                        >
                          Enable Camera
                        </button>
                      </div>
                    )}



                  </div>
                </div>




              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LeftNav;

